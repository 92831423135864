import React from 'react'
import { Heading } from '@pancakeswap-libs/uikit'


export default function ComingSoon() {
 
  return (
    <>
      <Heading color="primary" size="xxl">Coming Soon</Heading>
    </>
  )
}
